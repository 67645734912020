import axios from "axios";
import lodash from "lodash";
import { ethers } from "ethers";
import { getETHPrice } from "./zksync_era";

async function getLatestTransaction(limit, page, toDate) {
    var url = `https://block-explorer-api.mainnet.zksync.io/transactions`;
    var response = await axios({
        method: 'get',
        url: url,
        params: {
            limit: limit,
            page: page,
            toDate: toDate
        }
    });
    if (response.status !== 200) {
        return [];
    }
    return response.data.items;
}

async function getTransactions() {
    var jobs = [];
    var limit = 100;
    var toDate = new Date().toISOString();
    for(var i = 1; i <= 10; i++) {
        jobs.push(getLatestTransaction(limit, i, toDate));
    }
    var results = await Promise.allSettled(jobs);
    var transactions = lodash.flatten(results.map(result => result.value));
    return transactions;
}

export async function getGasStats() {
    var transactions = await getTransactions();
    transactions = transactions.filter(transaction => transaction.fee > 0);
    transactions.sort((a, b) => a.fee - b.fee);
    var fees = transactions.map(transaction => parseFloat(ethers.utils.formatEther(transaction.fee))).filter(fee => fee > 0);
    var ethPrice = getETHPrice(transactions);
    var low = fees[0] * ethPrice;
    var high = fees[fees.length-1] * ethPrice;
    var average = lodash.mean(fees) * ethPrice;
    const fixedDecimals = 2;
    var lowestFeeTransaction = transactions[0];
    console.log(lowestFeeTransaction);
    return {
        low: low.toFixed(fixedDecimals),
        average: average.toFixed(fixedDecimals),
        high: high.toFixed(fixedDecimals),
        transaction: {
            fee: low.toFixed(fixedDecimals),
            hash: lowestFeeTransaction.hash,
            time: new Date(lowestFeeTransaction.receivedAt).toLocaleString()
        }
    }
}