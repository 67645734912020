import { getGasStats } from "./apis/gas_stats";
import React from 'react';
import { Card, Col, Row, Statistic, Table } from 'antd';
import './GasTracker.css';

const { Meta } = Card;
const { Countdown } = Statistic;

const columns = [
  {
    title: "Fee",
    dataIndex: "fee",
    key: "fee",
    sorter: {
      compare: (a, b) => a.ethereum - b.ethereum
    },
    width: 150,
    render: text => <p>${text}</p>
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    sorter: {
      compare: (a, b) => a.ethereum - b.ethereum
    },
    width: 200
  },
  {
    title: "Tx Hash",
    dataIndex: "hash",
    key: "txHash",
    render: text => <a href={"https://explorer.zksync.io/tx/" + text} target="_blank">{text}</a>
  },
];

export default function GasTracker() {
  const [data, setDataSource] = React.useState([]);
  const [low, setLow] = React.useState(0);
  const [average, setAverage] = React.useState(0);
  const [high, setHigh] = React.useState(0);
  const [deadline, setDeadline] = React.useState(Date.now() + 1000 * 60);

  React.useEffect(() => {
    getGasStats().then((stats) => {
      setLow(stats.low);
      setAverage(stats.average);
      setHigh(stats.high);
      var copy = [stats.transaction, ...data];
      setDataSource(copy);
    });
  }, []);

  const onFinish = () => {
    getGasStats().then((stats) => {
      setLow(stats.low);
      setAverage(stats.average);
      setHigh(stats.high);
      var copy = [stats.transaction, ...data];
      setDataSource(copy);
    });
    setDeadline(Date.now() + 1000 * 60);
  };

  return (
    <>
    <div style={{ minHeight: '100vh' }}>
      <Card title="Zksync Era Realtime Gas Fee" style={{ marginBottom: "20px" }} className="gas-tracker-card">
        <Countdown value={deadline} onFinish={onFinish} prefix="Next update in " suffix="s" format="ss" valueStyle={{ color: "GrayText", fontSize: "15px", marginBottom: "10px" }} />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card className="my-card" bordered={false}>
              <Statistic title="Low" value={low} prefix="$" valueStyle={{ color: "green" }} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card className="my-card" bordered={false}>
              <Statistic title="Average" value={average} prefix="$" valueStyle={{ color: "blue" }} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card className="my-card" bordered={false}>
              <Statistic title="High" value={high} prefix="$" valueStyle={{ color: "red" }} />
            </Card>
          </Col>
        </Row>
        <Row gutter={12} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Card>
              <Meta title="Lowest Fee Transactions" />
              <Table dataSource={data}
                columns={columns}
                style={{ marginTop: 20 }}
                scroll={{ y: 200 }}
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
    </>
  );
}
