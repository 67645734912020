import {ethers} from "ethers";
import {getETHPrice} from "./cryptocompare";
import {sum,map} from "lodash";

const ZKSYNC_LITE_RPC = "https://api.zksync.io/jsrpc";
const ZKSYNC_LITE_API = "https://api.zksync.io/api/v0.1";
const ZKSYNC_LITE_API_2 = "https://api.zksync.io/api/v0.2";

const DAY = 1000 * 60 * 60 * 24;
const HOUR = 1000 * 60 * 60;
const MINUTE = 1000 * 60;

const TOKEN = {
    ETH: {
        "address": "0x0000000000000000000000000000000000000000",
        "id": 0,
        "decimals": 18,
        "symbol": "ETH",
        "enabledForFees": true
    },
    USDC: {
        "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        "id": 2,
        "decimals": 6,
        "symbol": "USDC",
        "enabledForFees": true
    },
    USDT: {
        "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
        "id": 4,
        "decimals": 6,
        "symbol": "USDT",
        "enabledForFees": true
    },
    DAI: {
        "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
        "id": 1,
        "decimals": 18,
        "symbol": "DAI",
        "enabledForFees": true
    }
}

async function fetchAccountInfo(address) {
    const response = await fetch(ZKSYNC_LITE_RPC, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(
            {"jsonrpc": "2.0", "id": 1, "method": "account_info", "params": [address]}
        )
    });
    const data = await response.json();
    return data.result;
}

async function fetchToken(symbol) {
    var url = `${ZKSYNC_LITE_API_2}/tokens/${symbol}`;
    var response = await fetch(url);
    var data = await response.json();
    return {
        address: data.result.address,
        id: data.result.id,
        decimals: data.result.decimals,
        symbol: data.result.symbol,
        enabledForFees: data.result.enabledForFees
    }
}

async function fetchBalance(address, token = "ETH") {
    var accountInfo = await fetchAccountInfo(address);
    var balances = accountInfo.committed.balances;
    if (token in balances) {
        return balances[token];
    } else {
        return ethers.BigNumber.from(0);
    }
}

export async function getZksyncLiteBalance(address) {
    return parseFloat(ethers.utils.formatEther(await fetchBalance(address, TOKEN.ETH.symbol))).toFixed(4);
}

export async function fetchNonce(address) {
    var accountInfo = await fetchAccountInfo(address);
    return accountInfo.committed.nonce;
}

export async function getTransactions(address) {
    /**
     * get all transactions of an address
     */
    var txs = [];
    var start = 0;
    var step = 100;
    while (true) {
        try {
            var url = `${ZKSYNC_LITE_API}/account/${address}/history/${start}/${step}`;
            var response = await fetch(url);
            var newTxs = await response.json();
            if (newTxs === null || newTxs.length === 0) {
                break;
            }
            txs = txs.concat(newTxs);
        } catch (error) {
            console.log(error);
            break;
        }
        start += step;
    }
    return txs;
}

function getFee(tx) {
    const txTypes = new Set(["Swap", "Transfer", "MintNFT"]);
    if (tx.success && txTypes.has(tx.tx.type)) {
        switch (tx.tx.feeToken) {
            case TOKEN.ETH.id:
                var price = getETHPrice();
                return parseFloat(ethers.utils.formatEther(tx.tx.fee)) * price;
            case TOKEN.USDC.id:
                return parseFloat(ethers.utils.formatUnits(tx.tx.fee, TOKEN.USDC.decimals));
            case TOKEN.USDT.id:
                return parseFloat(ethers.utils.formatUnits(tx.tx.fee, TOKEN.USDT.decimals));
            case TOKEN.DAI.id:
                return parseFloat(ethers.utils.formatUnits(tx.tx.fee, TOKEN.DAI.decimals));
            default:
                return 0;
        }
    }
    return 0;
}

export async function getTotalFeeCost(txs) {
    var totalFee = sum(txs.map(tx => getFee(tx)));
    return totalFee.toFixed(2);
}

export async function getLastTxTime(transactions) {
    var lastTx = transactions[0];
    var date = new Date(lastTx.created_at);
    const now = new Date();
    const diff = now.getTime() - date.getTime();

    if (diff >= DAY) {
        var days = Math.floor(diff / DAY);
        return `${days} days`;
    } else if (diff >= HOUR) {
        var hours = Math.floor(diff / HOUR);
        return `${hours} hours`;
    } else if (diff >= MINUTE) {
        var minutes = Math.floor(diff / MINUTE);
        return `${minutes} mins`;
    } else {
        return "Recent";
    }
}

export async function getActiveDays(transactions) {
    const transactionDates = transactions.map(transaction => {
        const date = new Date(transaction.created_at);
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const activeDaysSet = new Set(transactionDates.map(date => date.getTime()));
    const activeDays = activeDaysSet.size;
    return activeDays;
}

export async function getActiveWeeks(transactions) {
    const transactionDates = transactions.map(transaction => {
        const date = new Date(transaction.created_at);
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const activeDaysSet = new Set(transactionDates.map(date => date.getTime()));
    const activeWeeksSet = new Set(map(Array.from(activeDaysSet), timestamp => Math.floor(timestamp / (1000 * 60 * 60 * 24 * 7))));
    const activeWeeks = activeWeeksSet.size;
    return activeWeeks;
}

export async function getActiveMonths(transactions) {
    const transactionDates = transactions.map(transaction => {
        const date = new Date(transaction.created_at);
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const activeDaysSet = new Set(transactionDates.map(date => date.getTime()));
    const activeMonthsSet = new Set(map(Array.from(activeDaysSet), timestamp => Math.floor(timestamp / (1000 * 60 * 60 * 24 * 30))));
    const activeMonths = activeMonthsSet.size;
    return activeMonths;
}
