export async function fetchETHPrice() {
    const response = await fetch(`https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD`);
    if (response.status !== 200) {
        return;
    } else {
        var data = await response.json();
        localStorage.setItem('ETHUSD', data["USD"]);
        console.log(`Fetch ETH price: ${data["USD"]}`);
    }
}

export function getETHPrice() {
    return parseInt(localStorage.getItem('ETHUSD'));
}