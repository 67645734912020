import { ethers } from 'ethers';

const ethProvider = new ethers.providers.JsonRpcProvider("https://eth.llamarpc.com");

export async function getEthBalance(walletAddress) {
    try {
        var balance = await ethProvider.getBalance(walletAddress);
        return parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
    } catch (error) {
        console.error(error);
        return null;
    }
}

export function isAddress(address) {
    return ethers.utils.isAddress(address);
}