import {ethers} from 'ethers';
import _ from 'lodash';
import {fetchETHPrice} from './cryptocompare';

const zkSyncProvider = new ethers.providers.JsonRpcProvider("https://mainnet.era.zksync.io");

const DAY = 1000 * 60 * 60 * 24;
const HOUR = 1000 * 60 * 60;
const MINUTE = 1000 * 60;

export async function fetchTokenList(address) {
    /**
     * @param {string} address
     */
    const response = await fetch(`https://block-explorer-api.mainnet.zksync.io/address/${address}`);
    if (response.status !== 200) {
        return null;
    }
    return await response.json();
}

export async function getTxAndTransfer(address) {
    var transactions = await getTransactions(address);
    var transfers = await getTransfers(address);
    return {
        "transactions": transactions,
        "transfers": transfers
    }
}

export async function getTransactions(address) {
    var limit = 100;
    var toDate = new Date().toISOString();
    const response = await fetch(`https://block-explorer-api.mainnet.zksync.io/transactions?limit=${limit}&address=${address}&toDate=${toDate}`);
    if (response.status !== 200) {
        return null;
    } else {
        var data = await response.json();
        var transactions = data["items"];
        var {totalItems, itemCount, itemsPerPage, totalPages, currentPage} = data["meta"];
        var urls = [];
        while (totalPages > currentPage) {
            urls.push(`https://block-explorer-api.mainnet.zksync.io/transactions?page=${currentPage + 1}&limit=${limit}&address=${address}&toDate=${toDate}`);
            currentPage += 1;
        }
        var promises = urls.map(url => fetch(url).then(response => response.json()));
        var result = await Promise.allSettled(promises);
        var remainTransactions = result.filter(result => result.status === "fulfilled").map(result => result.value.items).flat();
        var allTransactions = transactions.concat(remainTransactions);
        return {"total": totalItems, "list": allTransactions};
    }
}

export async function getTransfers(address) {
    var limit = 100;
    var toDate = new Date().toISOString();
    const response = await fetch(`https://block-explorer-api.mainnet.zksync.io/address/${address}/transfers?limit=${limit}&toDate=${toDate}`);
    if (response.status !== 200) {
        return null;
    } else {
        var data = await response.json();
        var transactions = data["items"];
        var {totalItems, itemCount, itemsPerPage, totalPages, currentPage} = data["meta"];
        var urls = [];
        while (totalPages > currentPage) {
            urls.push(`https://block-explorer-api.mainnet.zksync.io/address/${address}/transfers?limit=${limit}&toDate=${toDate}&page=${currentPage + 1}`);
            currentPage += 1;
        }
        var promises = urls.map(url => fetch(url).then(response => response.json()));
        var result = await Promise.allSettled(promises);
        var remainTransactions = result.filter(result => result.status === "fulfilled").map(result => result.value.items).flat();
        var allTransactions = transactions.concat(remainTransactions);
        return {"total": totalItems, "list": allTransactions};
    }
}

function isBridgeTransaction(transaction) {
    return transaction.isL1Originated;
}

export async function getBridgeValue(transactions, transfers) {
    var bridgeTxs = _.filter(transactions["list"], transaction => isBridgeTransaction(transaction));
    var txHashSet = new Set(bridgeTxs.map(tx => tx.hash));
    var bridgeTransfers = _.filter(transfers["list"], transfer => txHashSet.has(transfer.transactionHash) && transfer.type === "deposit");
    var sum = _.reduce(bridgeTransfers, (sum, transfer) => sum.add(ethers.BigNumber.from(transfer.amount)), ethers.BigNumber.from(0));
    return (parseFloat(ethers.utils.formatEther(sum)) * getETHPrice()).toFixed(2);
}

export async function getTotalFeeCost(transactions) {
    var sum = _.reduce(transactions["list"], (sum, transaction) => sum.add(ethers.BigNumber.from(transaction.fee)), ethers.BigNumber.from(0));
    return (parseFloat(ethers.utils.formatEther(sum)) * getETHPrice()).toFixed(2);
}

export function getETHPrice() {
    return parseInt(localStorage.getItem('ETHUSD'));
}

function getTransferValueInUsdc(transfer) {
    if (transfer.token === null) {
        // unrecognaized token is considered no value
        return 0;
    } else {
        var coinPrice = {
            "ETH": getETHPrice(),
            "USDC": 1,
        }
        if (coinPrice[transfer.token.symbol.toUpperCase()] === undefined) {
            return 0;
        } else {
            var price = coinPrice[transfer.token.symbol.toUpperCase()] 
            return (transfer.amount / (10 ** transfer.token.decimals)) * price;
        }
    }
}

export async function getLastTxTime(transactions) {
    var lastTx = transactions["list"][0];
    var date = new Date(lastTx.receivedAt);
    const now = new Date();
    const diff = now.getTime() - date.getTime();

    if (diff >= DAY) {
        var days = Math.floor(diff / DAY);
        return `${days} days`;
    } else if (diff >= HOUR) {
        var hours = Math.floor(diff / HOUR);
        return `${hours} hours`;
    } else if (diff >= MINUTE) {
        var minutes = Math.floor(diff / MINUTE);
        return `${minutes} mins`;
    } else {
        return "Recent";
    }
}

export async function getActiveDays(transactions) {
    const transactionDates = transactions["list"].map(transaction => {
        const date = new Date(transaction.receivedAt);
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const activeDaysSet = new Set(transactionDates.map(date => date.getTime()));
    const activeDays = activeDaysSet.size;
    return activeDays;
}

export async function getActiveWeeks(transactions) {
    const transactionDates = transactions["list"].map(transaction => {
        const date = new Date(transaction.receivedAt);
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const activeDaysSet = new Set(transactionDates.map(date => date.getTime()));
    const activeWeeksSet = new Set(_.map(Array.from(activeDaysSet), timestamp => Math.floor(timestamp / (1000 * 60 * 60 * 24 * 7))));
    const activeWeeks = activeWeeksSet.size;
    return activeWeeks;
}

export async function getActiveMonths(transactions) {
    const transactionDates = transactions["list"].map(transaction => {
        const date = new Date(transaction.receivedAt);
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const activeDaysSet = new Set(transactionDates.map(date => date.getTime()));
    const activeMonthsSet = new Set(_.map(Array.from(activeDaysSet), timestamp => Math.floor(timestamp / (1000 * 60 * 60 * 24 * 30))));
    const activeMonths = activeMonthsSet.size;
    return activeMonths;
}

export async function getTotalValue(transfers) {
    await fetchETHPrice();
    var groupedTransfers = _.groupBy(transfers["list"], transfer => transfer.transactionHash);
    // select the transfer with the highest value for each transaction
    var representativeTransferVolumes = Object.values(groupedTransfers).map(transfers => _.max(transfers.map(transfer => getTransferValueInUsdc(transfer))));
    var totalValue = _.sum(representativeTransferVolumes);
    return totalValue.toFixed(2);
}

export async function getTransactionCount(address) {
    return await zkSyncProvider.getTransactionCount(address);
}

async function getCurrencyBalance(currency, balances) {
    var balance = Object.values(balances).find(balance => balance.token != null && balance.token.symbol === currency);
    return balance ? balance.balance / (10 ** balance.token.decimals) : 0;
}

export async function getZksyncEraEthBalance(tokens) {
    return parseFloat(await getCurrencyBalance('ETH', tokens)).toFixed(2);
}

export async function getWethBalance(tokens) {
    return parseFloat(await getCurrencyBalance('WETH', tokens)).toFixed(2);
}

export async function getUsdcBalance(tokens) {
    return parseFloat(await getCurrencyBalance('USDC', tokens)).toFixed(2);
}
