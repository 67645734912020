import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Divider, Input, Typography, Statistic, Result } from 'antd';
import {
  getZksyncEraEthBalance,
  getWethBalance,
  getUsdcBalance,
  getTotalFeeCost,
  getTotalValue,
  getTransactionCount,
  getActiveWeeks,
  getActiveDays,
  getActiveMonths,
  getBridgeValue,
  getLastTxTime,
  fetchTokenList,
  fetchLeaderboard,
  isAddress,
  getTxAndTransfer,
  fetchETHPrice
} from './apis';
import './Account.css';

const { Search } = Input;

const referLink = 'https://x.com/intent/follow?screen_name=tiger_web3';

const Account = () => {
  const [skipReferLink, setSkipReferLink] = useState(JSON.parse(localStorage.getItem('skipReferLink')) || false);
  const [addr, setAddr] = useState(null);
  const [loading, setLoading] = useState(true);
  const [balances, setBalances] = useState({
    eth: { key: 0, label: 'ETH', value: null },
    weth: { key: 1, label: 'WETH', value: null },
    usdc: { key: 2, label: 'USDC', value: null },
  });
  const [actives, setActives] = useState({
    last: { key: 3,  label: 'Last Transaction', value: null },
    days: { key: 4,  label: 'Active day(s)', value: null },
    weeks: { key: 5, label: 'Active week(s)', value: null },
    months: { key: 6, label: 'Active month(s)', value: null },
  });
  const [data, setData] = useState({
    rank: { key: 7, label: 'Rank', value: null },
    rankPercentage: { key: 8, label: 'Rank Percentage', value: null },
    bridge: { key: 10, label: 'Bridged Value (USD)', value: null },
    value: { key: 11, label: 'Transaction Volume (USD)', value: null },
    gas: { key: 9, label: 'Gas Cost (USD)', value: null },
    count: { key: 12, label: 'Transaction Count', value: null },
  })

  const dollarize = (value) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

  const fetchInfo = (address) => {
    if (address === null) {
      return;
    }

    setLoading(true);
    fetchTokenList(address).then((result) => {
      if (result === null && result.balances === null) {
          return;
      }
      getZksyncEraEthBalance(result.balances).then(value => {
        var balancesCopy = {...balances};
        balancesCopy.eth.value = value;
        setBalances(balancesCopy);
      });
      getWethBalance(result.balances).then(value => {
        var balancesCopy = {...balances};
        balancesCopy.weth.value = value;
        setBalances(balancesCopy);
      });
      getUsdcBalance(result.balances).then(value => {
        var balancesCopy = {...balances};
        balancesCopy.usdc.value = value;
        setBalances(balancesCopy);
      });
    });
    getTxAndTransfer(address).then((result) => {
      var transactions = result["transactions"];
      var transfers = result["transfers"];
      if (transactions === null) {
        return;
      }
      getActiveDays(transactions).then(value => {
        var activesCopy = {...actives};
        activesCopy.days.value = value;
        setActives(activesCopy);
      });
      getActiveWeeks(transactions).then(value => {
        var activesCopy = {...actives};
        activesCopy.weeks.value = value;
        setActives(activesCopy);
      });
      getActiveMonths(transactions).then(value => {
        var activesCopy = {...actives};
        activesCopy.months.value = value;
        setActives(activesCopy);
      });
      getLastTxTime(transactions).then(value => {
        var activesCopy = {...actives};
        activesCopy.last.value = value;
        setActives(activesCopy);
      });

      getBridgeValue(transactions, transfers).then(value => {
        var dataCopy = {...data};
        dataCopy.bridge.value = dollarize(value);
        setData(dataCopy);
      });
      getTotalFeeCost(transactions).then(value => {
        var dataCopy = {...data};
        dataCopy.gas.value = dollarize(value);
        setData(dataCopy);
      });
      getTotalValue(transfers).then(value => {
        var dataCopy = {...data};
        dataCopy.value.value = dollarize(value);
        setData(dataCopy);
      });
    });
    getTransactionCount(address).then(value => {
      var dataCopy = {...data};
      dataCopy.count.value = value;
      setData(dataCopy);
    });
    fetchLeaderboard([address]).then((result) => {
      var dataCopy = {...data};
      if (result.length == 1 && result[0].rank === null) {
        dataCopy.rank.value = 'N/A';
        dataCopy.rankPercentage.value = 'N/A';
        setData(dataCopy);
        return;
      }
      var rank = result[0].rank;
      var total = 4894227;
      var percentage = Math.round(rank * 100 / total);
      if (percentage === 0) {
        percentage = 1;
      }
      var msg = `Top ${percentage}%`;
      dataCopy.rank.value = Intl.NumberFormat('en-US').format(rank);
      dataCopy.rankPercentage.value = msg;
      setData(dataCopy);
    });
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const address = searchParams.get('address');
    fetchInfo(address);
    setAddr(address);
    fetchETHPrice();
  }, []);
  
  const onSearch = (value) => {
    if (!skipReferLink) {
      localStorage.setItem('skipReferLink', true);
      window.open(referLink, '_blank');
      setSkipReferLink(true);
    }
    if (value === '' || !isAddress(value)) {
      return;
    }
    let address = value.trim();
    window.location.href ='/?address=' + address;
  };

  return (
    <>
    {
      addr === null ?
      <div style={{ minHeight: '100vh' }}>
        <Result
          title="Welcome to Minitool"
          status="success"
          subTitle="Get A Comprehensive Activity Overview of Your Zksync Era Wallet"
          extra={[
            <Search placeholder='search wallet address' style={{ width: '60%' }} onSearch={onSearch} size="large" allowClear enterButton="Search"/>
          ]}
        />
      </div>
      :
      <div style={{ height: '100vh' }}>
        <Card title={
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            {addr}
            <Search placeholder='search address' onSearch={onSearch} allowClear style={{ width: 400 }} />
          </div>
        }
          style={{ marginBottom: "20px",  }} className="gas-tracker-card">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {
              Object.values(data).map((item, index) => 
                <Col key={item.key} className="gutter-row" span={8}>
                  <Card className="account-card" loading={loading}>
                    <Statistic title={item.label} value={item.value} />
                  </Card>
                </Col>
              )
            }
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Card title="Balance" className="account-card" style={{ overflow: 'auto', maxHeight: '285px' }} loading={loading}>
                {
                  Object.values(balances).map((item, index) =>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                      <div>{item.label}</div>
                      <div>{item.value}</div>
                    </div>
                  ).reduce((result, item) => [result, <Divider />, item])
                }
              </Card>
            </Col>
            <Col className="gutter-row" span={12}>
              <Card className="account-card" loading={loading}>
                {
                  Object.values(actives).map((item, index) =>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                      <div>{item.label}</div>
                      <div>{item.value}</div>
                    </div>
                  ).reduce((result, item) => [result, <Divider />, item])
                }
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    } 
    </>
  );
};

export default Account;
