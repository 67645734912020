import React from 'react';
import {BrowserRouter, Route, Routes, Link} from "react-router-dom";
import {BarsOutlined, TwitterOutlined, AccountBookOutlined, LinkOutlined} from '@ant-design/icons';
import {Layout, Menu, theme } from 'antd';
import Dashboard from './Dashboard';
import Account from './Account';
import GasTracker from './GasTracker';
import { useLocation } from 'react-router-dom';

const {Header, Content, Sider} = Layout;

const NavigationMenu = () => {
    const location = useLocation();
    return (
        <Menu mode="inline" selectedKeys={[location.pathname]}>
            <Menu.Item key="/">
                <AccountBookOutlined/>
                <span>Wallet Analysis</span>
                <Link to="/"/>
            </Menu.Item>
            <Menu.Item key="/dashboard">
                <BarsOutlined/>
                <span>Multi Wallet</span>
                <Link to="/dashboard"/>
            </Menu.Item>
            <Menu.Item key="/gas_tracker">
                <AccountBookOutlined/>
                <span>Zksync Gas Tracker</span>
                <Link to="/gas_tracker"/>
            </Menu.Item>
            <Menu.Item key="/meson">
                <LinkOutlined/>
                <span><a href="https://meson.fi/?from=minttoolkit" target="_blank">Zksync Bridge</a></span>
            </Menu.Item>
            <Menu.Item key="/twitter">
                <TwitterOutlined/>
                <span><a href="https://twitter.com/tiger_web3" target="_blank">Follow Us</a></span>
            </Menu.Item>
        </Menu>
    )
};

const App = () => {
    const {token: {
            colorBgContainer
        }} = theme.useToken();

    return (
        <BrowserRouter>

            <Layout hasSider>
                <Sider style={
                    {
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        background: colorBgContainer
                    }
                }>
                    <NavigationMenu/>   
                </Sider>

                <Layout className="site-layout"
                    style={
                        {marginLeft: 200}
                }>
                    {/* <Header style={
                        {
                            padding: 0,
                            background: colorBgContainer
                        }
                    }/> */}
                    <Content style={
                        {
                            margin: '24px 16px 0',
                            overflow: 'initial'
                        }
                    }>
                        <Routes>
                            <Route exact path="/"
                                element={<Account/>}/>
                        </Routes>
                        <Routes>
                            <Route exact path="/gas_tracker"
                                element={<GasTracker/>}/>
                        </Routes>
                        <Routes>
                            <Route exact path="/dashboard"
                                element={<Dashboard />} />
                        </Routes>
                    </Content>
                </Layout>

            </Layout>
        </BrowserRouter>
    );
};

export default App;
