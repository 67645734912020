import axios from 'axios';

const backend = "https://minitoolkit.org";
const leaderboard = `${backend}/api/leaderboard`;

export async function fetchLeaderboard(addresses) {
    const response = await axios.post(leaderboard, {
        addresses: addresses
    });
    return response.data;
}